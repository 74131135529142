ul{
  list-style: none;
}

.nav-menu{
  position: absolute;
  left: 40%;
  transform: translateX(-51%);
  margin-top: 150px;
}

.nav-menu ul{
  padding-left: 53px;
  position: relative;
  padding-top: 21px;
}

.nav-menu ul::after {
  content: '';
  position: absolute;
  width: 4px;
  height: calc(140% - 0%);
  margin-left: -4px;
  top: 0;
  z-index: -1;
  margin-top: 50px;
}
.nav-menu ul li{
  padding-left: 29px;
}
.nav-menu ul li a{
  display: flex;
  
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  line-height: 1rem;
  font-weight: 500;
}
.nav-menu ul li a::before {
  content: '';
  position: absolute;
  background: rgb(0, 140, 255);
  width: 8px;
  height: 8px;
  left: -37px;
  top: 38%;
  transform: translateY(-50%);
  border-radius: 50px;
  z-index: 0;
}
.nav-menu ul li .bus-enabled::before{
  margin-left: -0.21234em;
  height: 20px;
  width: 20px;
}
.active-ul::after{
  background-color: mediumpurple ;
}
.wait-ul::after{
  background-color: #bfbfbf;
}
.wait-ul-end::after{
  height: 10%;
}
.nav-menu ul li .active-li::before{
  background:blueviolet;
  border: solid 2px blueviolet;
}
.nav-menu ul li .wait-li::before{
background: #9f9f9f;
border: solid 2px #828282;
}
.name-bus .name{
  color: rgb(119, 96, 96);
}
.name-bus{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 6px;
  width: 100%;
  left: 15px;
}
.name-bus .name-bus-h{
  line-height: 20px;
}
.name-bus .name-bus-h h4{
  font-size: 16px;
  font-weight: 700;
  width: 108px;
  height: 20px;
}
.name-bus .name-bus-h span{
  font-size: 16px;
  font-weight: 400;
}
.name-bus .name-bus-h .on-time{
  font-weight: 400;
}

/*************************/
.name-bus2 .name2{
  color: rgb(119, 96, 96);
}
.name-bus2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;

  border-radius: 6px;
  padding-right: 12.3em;
  z-index: -11;
  padding-left: 20px;
  padding-bottom: 7px;
  top: -2px;
  height: 100px;
}

.name-bus2-shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.name-bus2 .name-bus-h2{
  top: -10px;
  position: relative;
}
.name-bus2 .name-bus-h2 h4{
  font-size: 16px;
}
.name-bus2 .name-bus-h2 span{
  font-size: 14px;
  font-weight: 400;
}
.name-bus2 .name-bus-h2 .on-time2{
  font-weight: 400;
}
.active-li .icon-b{
  position: absolute;
  font-weight: 500;
  font-size: 16px;
  left: -38px;
  color: white;
  padding-right: 136px;
  bottom: 45px;
}
.arrived-at {
  position: absolute;
  width: 100%;
  top: 122px;
  background-color: white;
  margin-left: -134px;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 1em;
  padding-right: 14.5em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  height: 34px;
  border-end-end-radius: 6px;
  border-bottom-left-radius: 6px;
}

.arrived-at-c{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
}
.arrived-at-c p{
  padding-right: 144px;
}
.arrived-at-c span{
  color: rgb(141, 141, 248);
  font-weight: 600;
  user-select: none;
  
  cursor: pointer;
}
.arrived-color{
  background-color: #c3dfff;
}
.last-height{
  height: 10vh;
}
.future-time{
  color: rgb(252, 79, 79);
}
.refresh-div {
  position: absolute;
  width: 100px;
  border-radius: 50px;
  font-size: 10px;
  margin-left: 171px;
  top: 18px;
}
.refresh-div .refresh-c{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.refresh-div .refresh-c p{
  padding-right: 10px;
}
.refresh-div .refresh-c .icon{
  width: 20px;
  cursor: pointer;
}
.navbar-status{
  padding-left: 20px;
}
.navbar-status2{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 3rem;
  position: relative;

}
.navbar-status .text-status{
  display: flex;
  margin: 0;
  position: relative;
  bottom: 0px;
  left: 10px;
  align-items: center;
}
.navbar-status .text-status h5{
  font-size: 1rem;
}
.text-status-estimated {
  display: flex;
}
.navbar-status .text-status .text-status-estimated h6{
  display: flex;
  margin: 0;
  position: relative;
  bottom: 0px;
  left: 10px;
  align-items: center;
}
.icon-status{
  display: flex;
  align-items: center;
}
/*******************Navbar***********************/

.nav-top-bus{
  display: flex;
  flex-direction: row;
  
  background-color: #666666;
  height: 40px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  align-items: center;
}
.nav-top-bus2{
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: -11%;
}
.nav-top-bus .arrival{
  padding-right: 107px;
}

.nav-top-bus .arrival span,
.nav-top-bus .station span{
  font-size: 21px;
  color: white;
  font-weight: 400 !important;
}
.nav-top-bus .station{
  position: relative;
  left: -40px;
}
.navbar-bus{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
}
.navbar-bus .icon-back{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-bus .icon-back i{
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
}
.navbar-bus .words{
  width: 100%;
  color: white;
}
.open-gps-counter{
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6rem;
}
.open-gps-counter span span{
  color: rgb(7, 6, 6);
  font-weight: 500;
  font-size: 18px;
}
.open-gps{
  position: fixed;
  background-color: black;
  width: 100%;
  height: 5rem;
  bottom: 0;
  
  display: flex;
  row-gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.open-gps .text-gps{
  display: flex;
  padding-right: 1rem;
  padding-left: -110px;
  flex-direction: column;
}
.open-gps .text-gps p{
  font-size: 16px;
  position: relative;
  color: rgb(173, 173, 173);
  line-height: 0;
  font-weight: bold
  
}
.open-gps .text-gps span{
  color: white;
  font-weight: 500;
  
  font-size: 18px;
}
.ul-override-arrived{
  padding: 30px 0 30px;
}

.ul-override-arrived::after{
  margin-top: 63px !important;
}
.roll{
  pointer-events: none;
  
  animation: spin 2s linear infinite;
}
.div-fixed{
  position: fixed;
  width: 100% !important;
  z-index: 100;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#focusI{
  position: relative;
  top: -325px;
}
.arrived-at-firstly{
  position: absolute;
  width: 100%;
  top: 122px;
  background-color: white;
  left: -51px;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 169px;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  height: 34px;
  border-end-end-radius: 6px;
  border-bottom-left-radius: 6px;
}
.nav-menu-firstly-past{
  transform: translate(-50%, 3%) !important;
}
.arrived-at-firstly-future {
  position: absolute;
  width: 100%;
  top: 122px;
  background-color: white;
  left: -29px;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 124px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  height: 34px;
  border-end-end-radius: 6px;
  border-bottom-left-radius: 6px;
}
