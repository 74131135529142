@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

/*   color variables */
$clr-primary: purple;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;

/*   border radius */
$radius: 0.5rem;



body {
  font-family: Oxygen, sans-serif;
  background: $clr-gray200;
  overscroll-behavior: contain;
  margin: 0;
  padding: 0;
  //margin: 1rem;
}

.wrapper {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(12rem, 16rem));
  gap: 1rem;
  //justify-content: center;
  margin: 1rem;
  padding-bottom: 4rem;
}

.userPageWrapper {
  margin: 1rem
}

.busCardWrapper {
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
}

.card {
  overflow: hidden;
  box-shadow: 0 2px 20px $clr-gray300;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 200ms ease-in;
  background: white;

  &__image {
    height: 10rem;
    width: 100%;
    object-fit: cover;
  }

  &__title {
    padding: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__description {
    padding: 0 1rem;
  }

  &__center_text {
    padding: 1rem 1rem;
  }

  &__btn {
    padding: 1rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
    border: 2px solid $clr-primary;
    background: transparent;
    color: $clr-primary;
    border-radius: $radius;
    transition: background 200ms ease-in, color 200ms ease-in;

    &:hover {
      background: $clr-primary;
      color: white;
      cursor: pointer;
    }
  }

  &__bus_content {
    height: 40rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size : 16rem;
    background-position: bottom;

    &__input {
      margin: 1rem;
    }

    &__question_label {
      margin: 1rem;
      display: flex;
      align-items: left;
      justify-content: left;
    }

    &__direction {
      display: grid;
      grid-template-columns: 1fr 2fr;
      margin: 1rem;
    }

    &__to_filter {
      display: grid;
      grid-template-columns: 1fr 4fr;
      margin: 1rem;
    }

    &__center_label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__toggle {
      display: flex;
      align-items: center;
      overflow: scroll;
    }

    &__toggle_filter {
      display: flex;
      align-items: right;
      justify-content: right;
    }

    &__filter_icon {
      display: flex;
      align-items: right;
      justify-content: right;
      margin-right: 1rem;
    }
  }

  &__bus_status {
    width: 100%;
    background-repeat: no-repeat;
    background-size : 16rem;
    background-position: bottom;
    margin-bottom: 1rem;

    &__title {
      padding: 1rem;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
    }

    &__no_liveList_label {
      margin: 1rem;
      display: flex;
      align-items: left;
      justify-content: left;
      opacity: 1;
      animation: fade 5s linear;
    }

    @keyframes fade {
      0%,100% { opacity: 0 }
      50% { opacity: 1 }
    }
  }

  &__bus_time_posts_table {
    height: 20rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size : 16rem;
    background-position: bottom;

    &__input {
      margin: 1rem;
    }

  }

  &__post_content {
    height: 24rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size : 16rem;
    background-position: bottom;

    &__btn {
      padding: 1rem;
      font-family: inherit;
      font-weight: bold;
      font-size: 1rem;
      margin: 0rem 1rem 1rem 1rem;
      border: 2px solid $clr-primary;
      background: transparent;
      color: $clr-primary;
      border-radius: $radius;
      transition: background 200ms ease-in, color 200ms ease-in;
      width: -webkit-fill-available;
  
      &:hover {
        background: $clr-primary;
        color: white;
        cursor: pointer;
      }
    }

    &__description {
      text-align: center;
    }

    &__input {
      margin: 1rem;

      &_text {
        width: 100%
      }
    }

  }

  &:hover {
    transform: scale(1.02);
  }

  &__table {
    margin: 0.2rem 0.5rem;
  }

  &__grocery_content {
    height: 22rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size : 16rem;
    background-position: bottom;

    &__input {
      margin: 1rem;
    }
  }

  &__grocery_table {
    margin: 1rem 0.5rem;
  }

  &__admin_content {
    height: 25rem;
  }

  &__admin_content_busStop_form {
    margin-top: 2rem;
    margin-right: 4rem;
  }

}

.socialWrapper {
  margin: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  padding-bottom: 4rem;
}

.socialCard {
  box-shadow: 0 0 10px 5px $clr-gray300;
  border-radius: $radius;
  display: flex;

  &__img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }

  &__websiteIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__body {
    margin: 0.5rem;
    flex-grow: 1;
  }
  &__title {
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
  }
  &__description {
    line-height: 1.2rem;
  }
  &__website {
    font-size: 1rem;
    margin-top: 0.6rem;

  }

  &__url {
    border: none;
    border-top: 1px solid $clr-gray300;
    background-color: transparent;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;

    &:hover {
      color: $clr-primary;
    }
  }

  &__action_grid_container {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
}

.sideBySideLeftIcon {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-top: 0.2rem;
  padding-right: 0.5rem;
}

.sideBySideRightIcon {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}

.busRouteDirectionLabel {
  text-transform: none;
  font-family: Oxygen, sans-serif;
  color: black;
  padding: 0.4rem;
}

.busRouteFilterLabel {
  text-transform: none;
  font-family: Oxygen, sans-serif;
  color: black;
}

@media screen and (min-width: 600px) {
  .wrapper {
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(12rem, 16rem));
    gap: 2rem;
    justify-content: center;
    margin: 1rem;
    padding-bottom: 4rem;
  }

  .card {
    &__bus_content {
      height: 30rem;
      width: 25rem;
      background-repeat: no-repeat;
      background-size : 16rem;
      background-position: bottom;
  
      &__input {
        margin: 1rem;
      }

      &__toggle {
        margin: 1rem;
      }
    }
  }

  .busReview__post_content {
    width: 24rem;
  }
}

@media screen and (min-width: 600px) {
  .socialWrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .socialCard {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;

    &__img {
      width: 100%;
      height: 12rem;
    }
  }
}

.deliveryPageWrapper {
  display: grid;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.deliveryWrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  padding-bottom: 1rem;
}

.deliveryCard {
  box-shadow: 1px 1px 5px 0px $clr-gray300;
  border-radius: $radius;
  display: flex;
  background: white;

  &:hover {
    transform: scale(1.02);
  }

  &__img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    padding: 0.5rem;
    border-radius: 0.8rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__body {
    margin: 0.2rem 0.4rem;
    flex-grow: 1;
    border-right: 1px solid #e1e5ee;
    padding-right: 0.5rem;
  }
  &__title {
    line-height: 1.4rem;
    font-weight: bold;
    margin-top: 0.2rem;
  }
  &__description {
    line-height: 1.2rem;
    font-size:0.7rem
  }
  &__website {
    font-size: 1rem;
    margin-top: 0.6rem;

  }

  &__url {
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: $clr-primary;
    }
  }

  &__action_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  &__action_call_right {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }

  &__info_grid_container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-top: 0.5rem;
    border-top: 1px solid $clr-gray300;
    padding-top: 0.5rem;
  
    &__text {
      text-align: center;
      justify-content: center;
      display: flex;
      margin-top: 0.1rem;
    }
  }
}

.userProfileWrapper {
  margin: 0.6rem;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 5rem;
}

.profileContentCard {
  box-shadow: 0 0 10px 5px $clr-gray300;
  border-radius: $radius;
  display: flex;
  background: white;

  &:hover {
    transform: scale(1.02);
  }

  &__body {
    margin: 0.2rem 0.4rem;
    flex-grow: 1;
  }

  &__title {
    line-height: 1.4rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__description {
    line-height: 1.2rem;
    font-size:0.7rem
  }
}

.busReview {
  box-shadow: 0 0 10px 5px $clr-gray300;
  border-radius: $radius;
  display: flex;

  &__title {
    padding: 1rem;
    white-space: pre-line;
  }
  
  &__description {
    line-height: 1.2rem;
  }

  &__post_content {
    //width: 100%;
    background-size : 16rem;
    background-position: bottom;
    background-color: whitesmoke;
    margin: 0.5rem;
    border-radius: 0.5rem;

    &__btn {
      padding: 1rem;
      font-family: inherit;
      font-weight: bold;
      font-size: 1rem;
      margin: 0rem 1rem 1rem 1rem;
      border: 2px solid $clr-primary;
      background: transparent;
      color: $clr-primary;
      border-radius: $radius;
      transition: background 200ms ease-in, color 200ms ease-in;
      width: -webkit-fill-available;
  
      &:hover {
        background: $clr-primary;
        color: white;
        cursor: pointer;
      }
    }

    &__input {
      margin: 0.5rem;

      &_text {
        width: 100%
      }
    }
  }
}


.TCWrapper {
  margin: 1rem
}

.linebreak-wrapper {
  white-space: pre-wrap;
}

.grid-parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bus_post_icon {

}

.bus_post_icon_number {
  float: right;
}

.busInfoModalClose {
  display: flex;
  align-items: right;
  justify-content: right; 
}

.noInternet {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  padding: 1rem;
  margin-bottom: 2rem;
  left: 14%;
  bottom: 10%;
  background: #800080;
  color: white;
  border-radius: 0.5rem;
  border: 5px;
}

.busInfoModal {
  margin-top: 1rem;
  display:flex ;
  gap:1rem;
}

.locationMarkerIcon {
  transform: scale(1.5);
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}


.loginPageBody {
  width: 400px
}

@media screen and (max-width: 500px) {
  .loginPageBody {
    width: 100vw
  }
}
 
.gray-line{
  height:100px;
  width:4px;
  background-color:gray;
  display:inline-block;
  position:absolute;
  top:5px;
  left:0.5px;
  transition: 1s linear;
}
.line{
  height:0px;
  width:4px;
  background-color:green;
  display:inline-block;
  position:absolute;
  top:5px;
  left:0.5px;
  transition: 1s linear;
  z-index: 4;
}
.line2{
  height:100px;
  transition: 1s;
  width:4px;
  background-color:green;
  display:inline-block;
  position:absolute;
  top:5px;
  left:0.5px;
  transition: 1s;
  z-index: 4;
}

/* CSS */
.button-38 {
  background-color: #FFFFFF;
  border: 0;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #111827;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: .75rem 1rem;
  text-align: center;
  text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-38:hover {
  background-color: rgb(249,250,251);
}

.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-38:focus-visible {
  box-shadow: none;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.filter_blink {
  animation:filter_blink 2.5s linear infinite alternate 2.5s;
}

@keyframes filter_blink {
  80% {
    color:black;
  }
  90% {
    color: #b729b8;
  }
  100% {
    color:black;
  }
}

.scrollbox {
  background-image: 
    /* Shadows */ 
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    /* Shadow covers */ 
    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0));   

  background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
	
	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.is_platform_bg{
  background-color: #fafafaf3;
  height: 100vh;
}

.is_android_images{
    padding-top:20px;
    width :12rem;
    border-radius: 20PX;
    box-shadow: 10px 10px 10px#e1dfdf;
    display: block;
    margin:auto;
}
.is_android_divs{
  font-family:cursive;
  color: black;
  padding-bottom:10px;
  padding-top:40px;
}

.is_ios_divs{
  font-family:cursive ;
  color: black ;
  padding-bottom:10px;
  padding-top:30px;
}
.is_ios_images{
  width : 8rem;
  border-radius:20px;
  display: block;
  margin:auto;
  box-shadow: 10px 10px 10px#e1dfdf;
}

.isandroidbg {
  margin : 0.5rem;
  display:inline-block;
  position:absolute;
}
/* CSS */
.button-41 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #00D775, #00BD68);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
}

.button-41:hover {
  background: #00bd68;
}

.button-2 {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #7b6f6f;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.forum_bg{
  max-width: 30rem;
  margin: 0.8rem;
  background-color: #ffffff;
  border-radius: 10px;
  height: 95vh;
}

.message {
	box-sizing: border-box;
			padding: 0.5rem 0.8rem;
			margin: 1rem;
			background: #f7f6f6;
			border-radius: 1.125rem 1.125rem 1.125rem 0;
			min-height: 2.25rem;
			width: fit-content;
			max-width: 66%;
			font-size: 15px;
      font-family: serif;
      box-shadow: 0 0 10px 0px #d2d0d0;
			
}
.forum_text_field {
  border-radius:8px;
  height: 5rem;
  margin :0.5rem;
  box-shadow: 0 0 10px 0px #c6bfbf;
  &__area {
    align-items: left;
    padding-left: 0.5rem;
    display:flex;
    height:5rem;
    text-decoration:none;
  }
}

// @media screen and (min-width: 400px) {
//   .forum_text_field__area {
//     gap: 80vw
//   }
// }
 
// span 
//   font-family: "Montserrat", sans-serif; }

// .jump {
//   animation: jump 3s infinite ease; }

// @keyframes jump {
//   0% {
//     top: 0; }
//   50% {
//     top: -40px; }
//   100% {
//     top: 0; } }

// .coin {
//   margin: auto;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   top: 0;
//   height: 150px;
//   width: 150px; }

// .coin .front, .coin .back {
//   position: absolute;
//   height: 150px;
//   width: 150px;
//   background: #ffbd0b;
//   border-radius: 50%;
//   border-top: 7px solid #ffd84c;
//   border-left: 7px solid #ffd84c;
//   border-right: 7px solid #d57e08;
//   border-bottom: 7px solid #d57e08;
//   transform: rotate(44deg); }
//   .coin .front:before, .coin .back:before {
//     content: "";
//     margin: 35.5px 35.5px;
//     position: absolute;
//     width: 70px;
//     height: 70px;
//     background: #f0a608;
//     border-radius: 50%;
//     border-bottom: 5px solid #ffd84c;
//     border-right: 5px solid #ffd84c;
//     border-left: 5px solid #d57e08;
//     border-top: 5px solid #d57e08;
//     z-index: 2; }
//   .coin .front .currency, .coin .back .currency {
//     overflow: hidden;
//     position: absolute;
//     color: #ffbd0b;
//     font-size: 40px;
//     transform: rotate(-44deg);
//     line-height: 3.7;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     text-shadow: 0 3px 0 #cb7407;
//     z-index: 3;
//     border-radius: 50%; }
//     .coin .front .currency:after, .coin .back .currency:after {
//       content: "";
//       position: absolute;
//       height: 200px;
//       width: 40px;
//       margin: 20px -65px;
//       box-shadow: 50px -23px 0 -10px rgba(255, 255, 255, 0.22), 85px -10px 0 -16px rgba(255, 255, 255, 0.19);
//       transform: rotate(-50deg);
//       animation: shine 3s infinite ease; }

// @keyframes shine {
//   0% {
//     margin: 20px -65px; }
//   50% {
//     margin: 70px -85px; }
//   100% {
//     margin: 20px -65px; } }
//   .coin .front .shapes, .coin .back .shapes {
//     transform: rotate(-44deg);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%; }
//     .coin .front .shapes div, .coin .back .shapes div {
//       width: 20px;
//       height: 4px;
//       background: #d57e08;
//       border-top: 2px solid #c47207;
//       margin: 75px 7px; }
//       .coin .front .shapes div:before, .coin .back .shapes div:before {
//         content: "";
//         position: absolute;
//         width: 20px;
//         height: 4px;
//         background: #d57e08;
//         border-top: 2px solid #c47207;
//         margin: -10px 0; }
//       .coin .front .shapes div:after, .coin .back .shapes div:after {
//         content: "";
//         position: absolute;
//         width: 20px;
//         height: 4px;
//         background: #d57e08;
//         border-top: 2px solid #c47207;
//         margin: 8px 0; }
//   .coin .front .shape_l, .coin .back .shape_l {
//     float: left; }
//   .coin .front .shape_r, .coin .back .shape_r {
//     float: right; }
//   .coin .front .top, .coin .back .top {
//     font-size: 35px;
//     color: #d67f08;
//     text-align: center;
//     width: 100%;
//     position: absolute;
//     left: 0; }
//   .coin .front .bottom, .coin .back .bottom {
//     font-size: 10px;
//     color: #d67f08;
//     text-align: center;
//     width: 100%;
//     position: absolute;
//     left: 0;
//     bottom: 15px; }

// .coin .shadow {
//   width: 100%;
//   height: 20px;
//   background: rgba(0, 0, 0, 0.4);
//   left: 0;
//   bottom: -50px;
//   border-radius: 50%;
//   z-index: -1;
//   margin: 185px 7px 0 7px;
//   animation: swift 3s infinite ease; }

// @keyframes swift {
//   0% {
//     opacity: 0.8; }
//   50% {
//     opacity: 0.4;
//     transform: scale(0.8); }
//   100% {
//     opacity: 0.8; } }
