.skelton-text{
    animation: skeleton-loading 1s linear infinite alternate;
    width: 100%;
    color: transparent !important;
    font-size: 14px;
}
.skelton::after{
    display: none;
}
.skelton li a::before{
    display: none;
}
.name-bus-skelton{
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        top: 10px;
        left: 78px;
    
}
.name-bus2-skelton{
    padding-right: 220px !important;
}
@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
.skelton-nav{
  margin-top: 30px !important;
}
